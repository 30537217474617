.navbar-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 1rem;
    height: 80px;
    background: linear-gradient(to bottom, #000000, #1a1a1a);
  }
  
  .nav-item {
    margin-right: 1rem;
  }
  
  .nav-link {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    font-family: 'Love Ya Like A Sister', cursive;;
    color: #ffffff; /* Darker green */
    text-decoration: none;
    border-radius: 20px;
    background: #000000; /* White background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Button shadow */
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .nav-link:hover {
    background-color: #2c2c2c; /* Hover background color */
    transform: scale(1.05); /* Hover transform effect */
  }

  .active-link {
    background-color: #ffffff;
    color: #000000
  }
  