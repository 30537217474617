.content{
  display: flex;
  align-items: center; 
  width: 100%;
}

.content p {
  color: #666666;
  font-family: 'Kavivanar', cursive;
  font-size: 2.2vw;
  max-height: 80vh;
}

.content img {
  height: 80vh;
  max-width:48vw;
}
.arrow {
  max-height: 7vw;
  margin-left:10px;
  margin-right:10px;
  opacity:50%
}

.arrow:hover{
  transform:scale(1.15);
  opacity:100%
}
.radio {
  bottom:0;
  display:flex;
  justify-content: space-between;
  width:100%
}

.radio img{
  width: 75px;
  margin-left:10px;
  margin-right:10px;
  opacity: 50%;
}

.radio img:hover{
  transform:scale(1.05);
  opacity:100%;
}

@keyframes text-entrance-animation {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes text-exit-animation {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(200px);
  }
}


.text-exit{
  animation: text-exit-animation;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}

.text-entrance {
  animation: text-entrance-animation;
  animation-duration:0.25s;
}